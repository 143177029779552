<template>
	<div>
		<full-page ref="fullpage" :options="options" id="fullpage">
			<!-- 0首页 -->
			<div class="section section_one">
				<!-- headnav -->
				<div class="nav_top">
					<img src="../../public/static/indexPage/logo.png" alt="" class="logo" />
					<ul class="nav_li">
						<li>
							<router-link to="/">首页</router-link>
						</li>
						<span>/</span>
						<li>
							<router-link to="/about">关于驾御</router-link>
						</li>
						<span>/</span>
						<li>
							<router-link to="/product">产品</router-link>
						</li>
						<span>/</span>
						<li>
							<router-link to="/news">新闻动态</router-link>
						</li>
						<span>/</span>
						<!--          <li><router-link to="/time">时光溯影</router-link></li>-->
						<!--          <span>/</span>-->
						<li>
							<router-link to="/contact">联系我们</router-link>
						</li>
					</ul>
					<!-- <img src="../../public/static/indexPage/nav.png" alt="" class="nav" @click="toggleDisplay"> -->
				</div>
				<div class="first_page_view">
					<div class="company_target animate__animated" :class="{'animate__bounceInUp':classShow}">
						<h1 class="smart_title">驾御科技将致力于</h1>
						<h1 class="smart_title">成为全球领先的出行服务商</h1>
						<p class="smart_intro">建设无缝、个性化出行聚合服务平台</p>
						<p class="smart_intro">
							构建数字出行生态体系，将数字科技和智能网联汽车与自驾出行相融合
						</p>
						<p class="smart_intro">
							为客户提供定制化的出行服务解决方案，给予车主极致的人机交互体验
						</p>
					</div>
				</div>
			</div>
			<!-- 1证书 -->
			<div class="section">
				<div class="cer_father_view">
					<p class="smart_title">热烈祝贺我公司荣获“高新技术企业” 证书</p>
					<p class="cer_father_view_p2">
						近日，我司收到由上海市科学技术委员会、上海市财政局、国家税务总局上海市税务局联合颁发的“高新技术企业”证书，证书编号：GR202031006478,
						该证书有效期三年。国家高新技术企业门槛较高，认定须经过严格的测评和审批程序，驾御信息科技凭借过硬的技术实力和持续创新能力荣获高新技术企业认证，这标志着公司在研发和创新方面得到国家相关部门的肯定与认可。
					</p>
					<img src="../../public/static/indexPage/cer001.jpg" />
				</div>
			</div>
			<!-- 2 途途 -->
			<div class="section">
				<secondSection ref="secondSection"></secondSection>
			</div>
			<!-- 3 小墨鱼-->
			<div class="section">
				<router-link to="/yu">
					<div class="slide slide_two" data-anchor="slide2"></div>
				</router-link>
			</div>
			<!-- 4 合作 -->
			<div class="section">
				<fourthSection ref="fourthSection"></fourthSection>
			</div>
			<!-- 5contact -->
			<div class="section section_five">
				<div class="fifth_page">
					<contactAdd ref="contactAdd"></contactAdd>
					<!-- nav_bottom -->
					<bottomNav style="position:absolute;bottom:0px;width:100%"></bottomNav>
				</div>
			</div>
		</full-page>
	</div>
</template>

<script>
	// import rightBar from '../components/rightBar'
	import secondSection from "../components/secondSection";
	import fourthSection from "../components/fourthSection";
	import contactAdd from "../components/contactAdd";
	import bottomNav from "../components/bottomNav";
	export default {
		components: {
			// rightBar,
			secondSection,
			fourthSection,
			contactAdd,
			bottomNav
		},
		data() {
			return {
				classShow: false,
				isClicked: false,
				options: {
					menu: "#menu",
					anchors: ["page1", "page2", "page3", "page4", "page5","page6"],
					lockAnchors:true,
					licenseKey: "null",
					afterLoad: this.afterLoad,
					moveTo: this.moveTo
				},
			};
		},
		methods: {
			toggleDisplay: function() {
				this.isClicked = !this.isClicked
			},
			afterLoad(anchorLink, targetObj) {
				if (targetObj.anchor == 'page1' && targetObj.index === 0) {
					this.classShow = true
				}

				if (targetObj.anchor == 'page2' && targetObj.index === 1) {
					this.$refs.secondSection.handleClassShow()
				}

				if (targetObj.anchor == 'page4' && targetObj.index === 3) {
					this.$refs.fourthSection.handleClassShow()
				}

				if (targetObj.anchor == 'page5' && targetObj.index === 4) {
					this.$refs.contactAdd.handleClassShow()
				}
			}
		},
		beforeRouteLeave(to, from, next) {
			// console.log(this.$refs.fullpage);
			// fullpage_api.destroy("all");
			// this.$refs.fullpage.destory();
			next();
		},

	};
</script>

<style scoped>
	.cer_father_view {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.cer_father_view img {
		width: 50%;
		height: auto;
		margin-top: 40px;
		box-shadow: #ccc 0px 0px 30px;
	}

	.cer_father_view p {
		margin-left: 120px;
		margin-right: 120px;
		margin-top: 30px;
	}

	.cer_father_view_p1 {
		font-weight: bold;
		font-size: 30px;
	}

	.cer_father_view_p2 {
		font-size: 18px;
		text-indent: 35px;
		letter-spacing: 1px;
		line-height: 30px;
	}

	.section_one {
		background: url(../../public/static/indexPage/banner_a.png);
		height: 100%;
		background-size: cover;
	}

	.section_two {
		background: url(../../public/static/indexPage/ttt.png);
		height: 100%;
		background-size: cover;
	}

	.slide_one {
		background: url(../../public/static/indexPage/firstPage.png);
		background-size: cover;
	}

	.slide_two {
		background: url(../../public/static/indexPage/secondPage.png);
		background-size: cover;
	}

	.fifth_page {
		background: url(../../public/static/indexPage/fourthPage.png);
		background-size: cover;
		height: 100%;
	}

	p {
		margin: 0;
		padding: 0;
	}

	.sss {
		display: block;
	}

	.vis {
		display: none;
	}

	.nav_li li a {
		color: black;
	}

	.nav_color {
		color: red;
	}

	.part img {
		width: 50px;
	}

	/* nav-top */
	.nav_top {
		/* background: #ad1e23; */
		background: rgba(0, 0, 0, 0.2);
		display: flex;
		align-items: center;
		justify-content: space-between;
		/* padding: 30px; */
		height: 80px;
		top: 0px;
		left: 0px;
		right: 0px;
		position: fixed;
		min-width: 1200px;
	}

	.nav {
		width: 50px;
	}

	.fp-tableCell {
		vertical-align: baseline !important;
	}

	.nav_li {
		display: flex;
		list-style-type: none;
		position: relative;
		color: #fff;
	}

	.nav_li li a {
		color: #fff;
		text-decoration: none;
	}

	.nav_li li a:hover {
		text-decoration: underline;
	}

	.arrow_down {
		width: 3%;
		margin-top: 30px;
	}

	.company_target {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		background: #fff;
		width: 70%;
		text-align: center;
		padding-top: 30px;
		padding-bottom: 30px;
		margin-left: auto;
		margin-right: auto;
	}

	.first_page_view {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 100%;
	}

	.four_part {
		display: flex;
		justify-content: center;
	}

	.four_part .part {
		flex: 1;
	}

	.four_part .part p {
		font-weight: bold;
	}

	.smart_title {
		font-size: 60px;
		font-weight: bold;
		margin: 0;
	}

	.smart_intro,
	.smart_intro_red {
		font-size: 18px;
	}

	.smart_intro_red {
		color: #ad1e23;
	}

	.nav_bottom {
		position: absolute;
		bottom: 0px;
	}

	.section_five {
		position: relative;
	}

	/* @media screen and (min-width: 1200px) { */
		.logo {
			cursor: pointer;
			width: 150px;
			margin-left: 50px;
		}

		.nav {
			width: 40px;
		}

		.nav_li li {
			margin: 0 30px;
		}

		.nav_li {
			position: relative;
			/* left: 200px; */
		}

		.smart_title {
			font-size: 40px;
		}

		.company_target p {
			margin-top: 10px !important;
		}
	/* } */

	@media screen and (width: 1920px) {
		/* .nav_li {
    left: 400px;
  } */
	}
</style>
