<template>
  <div>
   <div id="nav">
      <img src="../../public/static/indexPage/logo.png" alt="" class="logo">
      <ul>
          <li><router-link to="/">首页</router-link></li>
          <span>/</span>
          <li><router-link to="/about">关于驾御</router-link></li>
          <span>/</span>
          <li><router-link to="/product">产品</router-link></li>
          <span>/</span>
          <li><router-link to="/news">新闻动态</router-link></li>
          <span>/</span>
<!--          <li><router-link to="/time">时光溯影</router-link></li>-->
<!--          <span>/</span>-->
          <li><router-link to="/contact">联系我们</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
// mounted () {
//     // window.addEventListener('scroll',this.handleScroll)
//     this.$nextTick(()=>{
//       window.onscroll = function() {
//         var headerMain = document.getElementById('nav');
//         if(window.pageYOffset > 0){
//           headerMain.classList.add('navChanged');
//         } else {
//           headerMain.classList.remove('navChanged');
//         }
//       }
//     })
//   }
}
</script>

<style scoped>
.navChanged {
  background: #ad1e23;
  transition:all 0.2s ease-in-out;
}
#nav {
  float: right;
  background: #ad1e23;
  position: fixed;
  width: 100%;
  min-width: 1200px;
  z-index: 2;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
ul {
  float: right;
  display: flex;
  list-style: none;
  color: #fff;
}
ul li {
  font-size: 16px;
  margin: 0 30px;
}
#nav ul li a {
  color: #fff;
  text-decoration: none;
}
.logo {
  width: 150px;
}
#nav {
  /* padding: 30px; */
}
@media screen and (min-width: 1200px) {
  .logo {
    width: 150px;
  }
  #nav {
    /* padding: 30px 0; */
  }
  #nav img {
    margin-left: 50px;
  }
}
@media screen and (min-width: 1920px) {
  ul li {
    font-size: 16px;
    margin: 0 18px;
  }
  .logo {
    width: 130px;
  }
    #nav {
    /* padding: 30px 0; */
  }
  ul {
    margin-right: 50px;
  }
  #nav img {
    margin-left: 50px;
  }
}
</style>
