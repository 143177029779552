<template>
  <div>
  <div class="rr">
    <headNavNo></headNavNo>
    <!-- operation -->
    <div id="yu">
      <img src="../../public/static/demo/yu.png" alt="" id="yu">
    </div>
    <div class="operation">
      <p>阿里巴巴旗下智能操作系统</p>
      <p>operating system</p>
      <img src="../../public/static/demo/yunos.png" alt="" id="yunos">
      <img src="../../public/static/demo/operation.png" alt="" id="operation">
    </div>
    <!-- cpu -->
    <div class="cpu">
      <p>强劲四核动力</p>
      <p>高通四核处理器</p>
      <p>阿里yunos操作系统，运行稳定，搭载1G高速运行内存，多任务流畅操作</p>
      <p>16G大容量存储空间，尽享海量精彩</p>
      <img src="../../public/static/demo/cpu.png" alt="">
    </div>
    <!-- music -->
    <div class="music">
      <p>音你所爱，乐随心动</p>
      <p>专业音频资源 听我想听</p>
      <p>对接虾米音乐源、支持本地音乐、在线音乐、蓝牙音乐、收藏音乐、可与阿里账号互通</p>
      <p>与桌面卡片交互，与语音对接进行先关指令控制，给桌面推荐卡片提供音乐推荐</p>
      <img src="../../public/static/demo/music.png" alt="">      
    </div>
    <div class="mod">
      <p>适配95%以上车型</p>
      <p>兼容性高</p>
      <p>9英寸/10.1英寸小墨鱼Ai中控大屏，适配于95%以上车型，可视角度大，</p>
      <p>智能语音声控，让您身临其境地感受智能行车的新时代！</p>
      <img src="../../public/static/demo/mod.png" alt="" id="mod">
    </div>
    <bottomNav></bottomNav>
  </div>
  <router-link to="/product">
      <div class="back">
        <img src="../../public/static/back/back.png" alt="">
      </div>  
    </router-link>
  </div>
</template>

<script>
import headNavNo from '@/components/headNavNo'
import bottomNav from '@/components/bottomNav'
export default {
  components: {
    headNavNo,
    bottomNav
  }
}
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
}
.rr {
  background: #f7f7f7;
}
.banner {
  background: url(../../public/static/demo/banner.png);
  background-size: cover;
  height: 450px;
}
.cpu, .operation, .music, .mod {
  text-align: center;
}
#operation {
  width: 100%;
}
#yunos {
  width: 200px;
  margin: 50px 0;
}
#yu {
  text-align: center;
  padding: 100px 0 0 0;
}
.operation, .cpu, .music, .mod{
  margin-top: 100px;
}
.operation p:nth-child(1) {
  color: #656464;
  font: 40px Microsoft YaHei bold;
  margin: 0px 0 10px 0;  
}
.operation p:nth-child(2) {
  color: #d6d6d6;
}
.cpu img {
  width: 50%;
}
.cpu p:nth-child(1), .music p:nth-child(1), .mod p:nth-child(1)  {
  font-size: 60px;
  color: #5F5F5F;
}
.cpu p:nth-child(2), .music p:nth-child(2), .mod p:nth-child(2) {
  font-size: 30px;
  color: #aaaaaa;
  font-weight: 300;
  margin: 20px 0;
}
.cpu p:nth-child(3), .cpu p:nth-child(4), .music p:nth-child(3), .music p:nth-child(4), .mod p:nth-child(3), .mod p:nth-child(4){
  font-size: 24px;
  line-height: 2;
}
.music img {
  width: 250px;
  margin: 40px 0;
}
#mod {
  width: 50%;
  margin-top: 20px;
  margin-bottom: 50px;
}
@media screen and (min-width: 1920px) {
  .banner {
    height: 580px;
  }
  .operation p:nth-child(1) {
    font: 54px Microsoft YaHei bold;
    font-family: Arial, Helvetica, sans-serif;
  }
  .operation p:nth-child(2) {
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
  }
  #yunos {
    width: 300px;
    margin: 50px 0;
  }
  .cpu p:nth-child(1), .music p:nth-child(1), .mod p:nth-child(1)  {
    font-size: 72px;
  }
  .cpu p:nth-child(2), .music p:nth-child(2), .mod p:nth-child(2) {
    font-size: 36px;
    margin: 40px 0;
  }
  .cpu p:nth-child(3), .cpu p:nth-child(4), .music p:nth-child(3), .music p:nth-child(4), .mod p:nth-child(3), .mod p:nth-child(4){
    font-size: 28px;
  }
}
.back img{
  position: fixed;
  left: 4%;
  bottom: 20%;
  width: 50px;
  height: 50px;
}
</style>