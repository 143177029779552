<template>
  <div @click="toTop">
    <button id="top">TOP</button>
  </div>
</template>

<script>
export default {
  // data () {
  //   return {
  //     scrollTop: 0,
  //     time: 0,
  //     dParams: 20,
  //     scrollState: 0
  //   }
  // },
  // mounted () {
  //   // window.addEventListener('scroll', this.getScrollTop);
  //   this.$nextTick(()=>{
  //     window.onscroll = function() {
  //       var backTop = document.getElementById('top');
  //       if(window.pageYOffset > 0){
  //         backTop.classList.add('changed');
  //       } else {
  //         backTop.classList.remove('changed');
  //       }
  //     }
  //   })
  // },
  // // computed:{
  //     showTop: function(){
  //       let value = this.scrollTop>200?true:false;
  //       return value;
  //     },
  //   },
  // methods: {
  //   toTop(e) {
  //     if(!!this.scrollState){
  //       return;
  //     }
  //     this.scrollState = 1;
  //     e.preventDefault();
  //     let distance = document.documentElement.scrollTop || document.body.scrollTop;
  //     let _this = this;
  //     this.time = setInterval(function(){ _this.gotoTop(_this.scrollTop-_this.dParams) }, 10);
  //   },
  //   gotoTop(distance){
  //     this.dParams += 20;
  //     distance = distance>0 ? distance : 0;
  //     document.documentElement.scrollTop = document.body.scrollTop = window.pageYOffset = distance;
  //     if(this.scrollTop < 10){
  //       clearInterval(this.time);
  //       this.dParams = 20;
  //       this.scrollState = 0;
  //     }
  //   },
  //   getScrollTop() {
  //     this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  //   }
  // }
  methods: {
    toTop (){
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style>
button {
  position: fixed;
  right: 3%;
  bottom: 10%;
  border: 1px solid;
  border-radius: 4px;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
button:hover {
  background: #ad1e23;
  transition: all 0.2s ease-in-out;
  color: #fff;
}
.changed {
  opacity: 1;
}
</style>
