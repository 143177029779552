<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld'
// import Index from "./views/Index.vue";

export default {
  name: "App",
  components: {},
  data() {
    return {
      //
    };
  }
};
</script>
