<template>
  <div>
    <headNavNo></headNavNo>
    <div class="board">
      <img src="../../public/static/paotuan/paotuan-logo.png" alt="" id="paotuan-logo">
      <p>优思酷是一款以校内外卖为基础的智慧校园APP。</p>
      <p>有效分流食堂高峰减轻人群缓解现场人多压力。</p>
      <p>外卖源自校内食堂食品安全有保障。</p>
      <p>轻松在校内转卖闲置物品。</p>
      <img src="../../public/static/paotuan/000.png" alt="" id="ttt">
      
    </div>
    <router-link to="/product">
      <div class="back">
        <img src="../../public/static/back/back.png" alt="">
      </div>
    </router-link>
    <bottomNav></bottomNav>
  </div>
</template>

<script>
import headNavNo from '@/components/headNavNo'
import bottomNav from '@/components/bottomNav'
export default {
  components: {
    headNavNo,
    bottomNav
  }
}
</script>

<style scoped>
.board {
  text-align: center;
  padding-top: 200px;
}
#ttt {
  width: 60%;
  margin-top: 50px;
}
#ttt-logo {
  width: 300px;
  margin-bottom: 50px;
}
#s1 {
  width: 40%;
}
h1 {
  position: relative;
}
.box {
  display: flex;
}
.border_bottom {
  width: 100px;
  border-top: 1px solid red;
  margin: 0px auto 30px auto;
}
/* h2::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  content: '';    
  width: 0;    
  border-top: solid  #5dd7bf 1px;
} */
.s1  {
  margin-top: 50px;
}
.s2 {
  width: 80%;
}
.feature {
  margin-top: 80px;
}
.feature p {
  padding: 0 50px;
}
.s3 {
  width: 40%;
}
.back img{
  position: fixed;
  left: 4%;
  bottom: 20%;
  width: 50px;
  height: 50px;
}
</style>
