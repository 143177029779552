<template>
  <div class="rr">
    <div class="banner">
    <headNav></headNav>
    </div>
    <h1 class="title title-top">驾御信息科技（上海）有限公司致力于</h1>
    <h1 class="title title-b">成为全球领先的出行服务商</h1>
    <div class="intro">
      <p>建设无缝、个性化出行聚合服务平台，构建数字出行生态体系，</p>
      <p>将数字科技和智能网联汽车与自驾出行相融合，利用自研的 "精准算法"</p>
      <p>为客户提供定制化的出行服务解决方案，给予车主极致的人机交互体验。</p>
      <p>平台业务涵盖淘途途“智慧出行APP”、炫彩瞬间“AI智能摄像系统”、出行服务大数据分析系统、</p>
      <p>车友组队系统、EC路书管理系统、行程规划系统等。为用户提供丰富多样的整体解决方案，</p>
      <p>涵盖数字自驾游线路选择、智能生活服务和数字沙盘，为用户提供一条龙服务及出行保障的全新数字化出行体验<span></span>。</p>
    </div>
    <div class="four_part">
      <div class="part">
        <img @mouseover='handleMaas' @mouseleave="handleLeave" class="animate__animated" :class="{'animate__flipInY':massShow}" src="../../public/static/indexPage/maas.png" alt="">
        <p>MAAS聚合平台</p>
      </div>
      <div class="part">
        <img @mouseover="handleVendor" @mouseleave="handleVendorLeave" class="animate__animated" :class="{'animate__flipInY':vendorShow}" src="../../public/static/indexPage/vendor.png" alt="">
        <p>智慧出行服务商</p>
      </div>
      <div class="part">
        <img @mouseover="handleCoo" @mouseleave="handleCooLeave" class="animate__animated" :class="{'animate__flipInY':cooShow}" src="../../public/static/indexPage/coo.png" alt="">
        <p>车路协同行业解决方案</p>
      </div>
      <div class="part">
        <img @mouseover="handleAi" @mouseleave="handleAiLeave" class="animate__animated" :class="{'animate__flipInY':aiShow}" src="../../public/static/indexPage/ai.png" alt="">
        <p>AI智能服务</p>
      </div>
    </div>
    <p></p>
    <div class="concept">
      <p class="concept_title">企业理念</p>
      <div class="culture">
        <div class="border"></div>
        <p class="sub_title">企业使命</p>
        <p>让世界更好玩</p>
      </div>
      <div class="culture">
        <div class="border"></div>
        <p class="sub_title">企业愿景</p>
        <p>成为全球领先的出行服务商</p>
      </div>
      <div class="culture">
        <div class="border"></div>
        <p class="sub_title">企业价值观</p>
        <p>同一个问题 不一样的思维</p>
      </div>
<!--      <div class="culture">-->
<!--        <div class="border"></div>-->
<!--        <p class="sub_title">企业文化</p>-->
<!--        <p>追求卓越无止境、与时俱进创未来</p>-->
<!--        <p>核心文化：创新、敬业、追求卓越</p>-->
<!--        <p>经营文化：为用户带来体验，为企业创造价值；与伙伴合作共赢，与社会共同进步</p>-->
<!--        <p>人才文化：人适其位，位适其人；打造知识型员工，共创学习型企业；使技能与业绩同提高，让员工与企业共成长</p>-->
<!--        <p>行为文化：从全局着眼，打造高效团队；从细节入手，狠抓执行落实</p>-->
<!--      </div>-->
<!--      <div class="culture">-->
<!--        <div class="border"></div>-->
<!--        <p class="sub_title">企业精神</p>-->
<!--        <p>思想开放，积极进取，勇往直前，坚持不懈</p>-->
<!--      </div>-->
    </div>
    <team></team>
    <bottomNav></bottomNav>
  </div>
</template>

<script>
import team from '@/components/team'
import headNav from '@/components/headNav'
import bottomNav from '@/components/bottomNav'
export default {
  data () {
    return {
      massShow:false,
      vendorShow:false,
      cooShow:false,
      aiShow:false
    }
  },
  components: {
    team,
    headNav,
    bottomNav
  },
  methods: {
    handleMaas(){
      setTimeout(()=>{
        this.massShow = true
      },500)
    },
    handleLeave(){
      setTimeout(()=>{
        this.massShow = false
      },1000)
    },
    handleVendor(){
      setTimeout(()=>{
        this.vendorShow = true
      },500)
    },
    handleVendorLeave(){
      setTimeout(()=>{
        this.vendorShow = false
      },1000)
    },
    handleCoo(){
      setTimeout(()=>{
        this.cooShow = true
      },500)
    },
    handleCooLeave(){
      setTimeout(()=>{
        this.cooShow = false
      },1000)
    },
    handleAi(){
      setTimeout(()=>{
        this.aiShow = true
      },500)
    },
    handleAiLeave(){
      setTimeout(()=>{
        this.aiShow = false
      },1000)
    }
  }
}
</script>

<style scoped>
  .banner {
    height: 450px;
  }
  .title {
    font-size: 40px;
    /*margin-top: 50px;*/
  }
  .title-top{
    margin-top: 40px;
  }
  .title-b{
    margin-bottom: 40px;
  }
  .part img {
    width: 50px;
  }
  .culture p:nth-child(3) {
    font-size: 20px !important;
    margin-top: 15px;
  }
  .sub_title {
    font-size: 22px !important;
    margin-top: 60px;
  }
  .intro >p:nth-child(3) {
    margin-top: 30px;
  }
  .concept_title {
    font-size: 36px;
    text-align: center;
    font-weight: 300; 
    margin-top: 30px !important;
  }
  .line {
    width: 80px;
    margin: 30px auto;
  }
  .culture {
    margin: 0 auto;
    width: 70%;
  }
  .border {
    height: 30px;
    width: 5px;
    margin-left:-15px;
  }
  .intro {
    line-height: 15px;
  }
  .four_part {
    width: 70%;
    margin: 50px auto 80px auto;
  }
@media screen and (min-width: 1440px) {
  .banner {
    height: 450px;
  }
  .title {
    font-size: 40px;
    /*margin-top: 50px;*/
  }
  .culture p:nth-child(3) {
    font-size: 20px !important;
    margin-top: 15px;
  }
  .sub_title {
    font-size: 22px !important;
    margin-top: 60px;
  }
  .intro >p:nth-child(3) {
    margin-top: 30px;
  }
  .concept_title {
    font-size: 36px;
    text-align: center;
    font-weight: 300; 
    margin-top: 30px !important;
  }
  .line {
    width: 80px;
    margin: 30px auto;
  }
  .culture {
    margin: 0 auto;
    width: 70%;
  }
  .border {
    height: 30px;
    width: 5px;
    margin-left:-15px;
  }
  .intro {
    line-height: 15px;
  }
  .four_part {
    width: 70%;
    margin: 50px auto 80px auto;
  }
}
@media screen and (min-width: 1920px) {
  .banner {
    height: 580px;
  }
  .title {
    font-size: 60px;
  }
  .culture p:nth-child(3) {
    font-size: 24px !important;
    margin-top: 15px;
  }
  .culture p {
    font-size: 22px;
  }
  .sub_title {
    font-size: 30px !important;
    margin-top: 100px;
  }
  .intro >p:nth-child(3) {
    margin-top: 30px;
  }
  .concept_title {
    font-size: 50px;
    text-align: center;
    font-weight: 300;
  }
  .line {
    width: 130px;
    margin: 30px auto;
  }
  .culture {
    margin: 0 auto;
    width: 70%;
  }
  .border {
    height: 38px;
    width: 5px;
    margin-left:-15px;
  }
  .intro {
    line-height: 15px;
  }
  .four_part {
    width: 70%;
    margin: 50px auto 100px auto;
  }
}



.banner {
  background: url(../../public/static/aboutUs/banner_c.png);
  background-size: 100% 100%;
}
.intro span {
  color: #ad1e23;
}
.title {
  text-align: center;
}
.intro {
  text-align: center;
}
.four_part {
  display: flex;
  justify-content: center;
  text-align: center;
}
.border {
  background: #ad1e23;
  position: absolute;
}
.line {
  border-top: 1px solid #ad1e23;
}
.four_part .part {
  flex: 1;
}
.four_part .part p {
  font-weight: bold;
}
.rr {
  min-width: 1024px;
}
</style>
