import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'

// import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'

import animated from 'animate.css'

Vue.use(animated)
import router from './router'
Vue.use(VueFullPage);

Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
