<template>
    <div>
        <div class="rr">
            <div>
                <headNavNo></headNavNo>
            </div>
            <div class="text-cs pd-text">
                <img src="../../public/static/demo/onejcsj.png">
            </div>
            <div class="text-cs mag-text">
                <span>
                    炫彩瞬间
                </span>
            </div>
            <div class="text-cs">
                <span>
                    是驾御研发的一项AI智能摄像系统项目
                </span>
            </div>
            <div class="text-cs">
                <span>
                    志在利用新颖的AI摄影技术
                </span>
            </div>
            <div class="text-cs">
                <span>
                    提升游客在景区的拍照体验
                </span>
            </div>
            <div class="text-cs mag-text">
                <img src="../../public/static/demo/yxc.png" style="max-width: 30%;">
            </div>
            <div class="text-cs mag-text">
                <span>
                    提供专属的云相册服务
                </span>
            </div>
            <div class="text-cs">
                <span>
                    为用户记录历史抓拍照片
                </span>
            </div>
            <div class="text-cs">
                <span>
                    把握每一个精彩瞬间
                </span>
            </div>
            <div class="text-cs mag-text">
                <img src="../../public/static/demo/there.png" style="max-width: 45%;">
            </div>
            <div class="text-cs mag-text">
                <span>
                    AI摄影师
                </span>
            </div>
            <div class="text-cs">
                <span>
                    精准的人脸识别
                </span>
            </div>
            <div class="text-cs">
                <span>
                    自动抓拍您不经意的精彩
                </span>
            </div>
            <div class="text-cs mag-text">
                <span>
                    扫描识别下方二维码，开启炫彩瞬间
                </span>
            </div>
            <div class="text-cs mag-text">
                <img src="../../public/static/demo/ewm.png" style="max-width: 45%;">

            </div>

        </div>
        <bottomNav></bottomNav>
    </div>
</template>

<script>
    import headNavNo from '@/components/headNavNo'
    import bottomNav from '@/components/bottomNav'
    export default {
        name: "xcsj",
        components: {
            headNavNo,
            bottomNav
        }
    }
</script>

<style scoped>
    p {
        margin: 0;
        padding: 0;
    }
    .rr {
        background: #f7f7f7;
    }
    .text-cs{
        text-align: center;
        width: 100%;
        margin-bottom: 16px;
    }
    .mag-text{
        margin-top: 50px;
    }
    .pd-text{
        padding-top: 100px;
    }
</style>