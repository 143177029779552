<template>
  <div class="div-cs">
    <!--<p>驾御打造智慧旅途app</p>
    <p>淘途途</p>-->
    <p  class="animate__animated" :class="{'animate__swing':classShow}"><img src="../../public/static/ttt/ttt-logo.png" style="height: 80px;margin-bottom: 10px;"/></p>
    <div class="animate__animated" :class="{'animate__bounceInLeft':classShow}">
      <p>淘途途是一款以PGC&UGC内容为基础的智慧旅途APP。</p>
      <p>手机端和车机端无缝连接，给您“行前、行中、行后”的一站式体验服务。</p>
      <p>淘途途聚集着大量的旅游大咖，提供海量高品质的精彩游记。行前智能规划、千人千面精品推荐和创新网联功能，为您的出行保驾护航。</p>
      <p>轻松记录精彩瞬间和行程轨迹，实时分享，快速晋级旅游大咖。</p>
    </div>
    <p>
      <img src="../../public/static/ttt/ttt.png" style="width: 40%;"/>
      <br/><br/><br/>
      <div class="content">
        <div class="static-content1">
            <img src="../../public/static/ttt/l1.png" style="margin: 0 20px" />
        </div>
        <div class="hover-content1">
            <img src="../../public/static/ttt/lo1.png" />
        </div>

        <div class="static-content2">
            <img src="../../public/static/ttt/l2.png" style="margin: 0 20px" />
        </div>
        <div class="hover-content2">
            <img src="../../public/static/ttt/lo2.png" />
        </div>
    </div>
   
  </div>
</template>

<script>
export default {
  data () {
    return {
      classShow:false
    }
  },
  methods: {
    handleClassShow(){
      this.classShow = true
    },
    hanleClassLeave(){
      this.classShow = false
    }
  }
}
</script>

<style scoped>
  .div-cs{
    height: 100%;
    box-sizing: content-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
  }
  p {
    text-align: center;
	font-size: 18px;
	letter-spacing: 1px;
	line-height: 12px;
  }
  .static-content1 {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 0 0 -100px;
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }
  .hover-content1 {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 0 0 -100px;
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }
  .content:hover .static-content1 {
    opacity: 0;
  }
  .content:hover .hover-content1 {
    opacity: 1;
  }
  .static-content2 {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 100px;
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }
  .hover-content2 {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 100px;
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }
  .content:hover .static-content2 {
    opacity: 0;
  }
  .content:hover .hover-content2 {
    opacity: 1;
  }
 /* p:first-child {
    font-size: 30px;
    letter-spacing: 2px;
    color: #fff;
    margin: 80px 0 20px 0 !important;
  }
  p:nth-child(2) {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    margin: 0;
  }*/
</style>
