<template>
  <div>
    <div class="contact">
      <div class="content">
        <div class="information animate__animated" :class="{'animate__bounceInDown':classShow}">
          <p>地址：上海市闵行区吴中路1059号灿虹精英大厦401</p>
          <p>邮箱：info@jiayuonline.com</p>
          <p>全国统一服务热线：</p>
          <p>400-801-6761</p>
          <p>021-64262842</p>
        </div>
        <div class="qrcode animate__animated" :class="{'animate__bounceInLeft':classShow}">
          <img src="../../public/static/indexPage/qrcode.jpg" alt="">
        </div>
        <div class="board animate__animated" :class="{'animate__bounceInRight':classShow}">
          <p>欢迎向我们留言您的意见和建议</p>
          <textarea id="textarea" class="transition"></textarea>
          <p><a href="mailto:sample@fly63.com?subject=test&cc=info@jiayuonline.com&subject=主题&body=" style="color: white">发送</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data: function () {
      return{
        classShow:false
      }
    },
    methods: {
      handleClassShow(){
        this.classShow = true
      },
      hanleClassLeave(){
         this.classShow = false
      }
    }
}
</script>

<style scoped>
.contact {
    top: 100px;
  }
  .content {
    padding: 30px 40px;
    margin: 0 40px;
  }
   .information p {
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 0.1em;
  }
  .qrcode img {
    width: 200px;
  }
  .transition {
    width: 200px;
    height: 200px;
    color: #000000;
    background-color: white;
  }
  .board {
    font-size: 14px;
  }
@media screen and (min-width: 1440px) {
  .contact {
    top: 100px;
  }
  .content {
    padding: 30px 40px;
    margin: 0 40px;
  }
   .information p {
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 0.1em;
  }
  .qrcode img {
    width: 200px;
  }
  .transition {
    width: 300px;
    height: 200px;
  }
  .board {
    font-size: 14px;
  }
}

@media screen and (min-width: 1920px) {
  .contact {
    top: 150px;
  }
  .content {
    padding: 80px 50px;
    margin: 0 40px;
  }
  .information p {
    line-height: 20px;
    font-size: 17px;
    letter-spacing: 0.1em;
  }
  .qrcode img {
    width: 300px;
  }
  .transition {
    width: 450px;
    height: 300px;
  }
}



.contact {
  background: #ad1e23;
  width: 85%;
  border-radius: 15px;
  margin: 0 auto;
  padding: 30px 0;
  position: relative;
}
.content {
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  vertical-align: middle;
  align-items: center;
}
.information p:nth-child(3) {
  margin-top: 30px;
}
.information p:nth-child(4) {
  margin-top: 30px;
}
.board p:nth-child(3){
  text-align: right;
}
/* animate动画 */
:root {
  --animate-delay: 0.1s;
}
</style>
