<template>
	<div class="fourth_page">
		<div class="partner_area">
			<ul>
				<li class="animate__animated animate__delay" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/1.png"></li>
				<li class="animate__animated animate__delay-1s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/2.png"></li>
				<li class="animate__animated animate__delay-2s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/3.png"></li>
				<li class="animate__animated animate__delay-3s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/4.png"></li>
				<li class="animate__animated animate__delay-4s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/5.png"></li>
				<li class="animate__animated animate__delay-3s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/6.png"></li>
				<li class="animate__animated animate__delay-2s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/7.png"></li>
				<li class="animate__animated animate__delay-1s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/8.png"></li>
				<li class="animate__animated animate__delay" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/9.png"
					 style="width:50%;"></li>
			</ul>
			<ul>
				<li class="animate__animated animate__delay" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/guangqi.png"></li>
				<li class="animate__animated animate__delay-1s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/11.png"></li>
				<li class="animate__animated animate__delay-2s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/qr.png"></li>
				<li class="animate__animated animate__delay-3s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/13.png"></li>
				<li class="animate__animated animate__delay-4s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/14.png"></li>
				<li class="animate__animated animate__delay-3s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/15.png"></li>
				<li class="animate__animated animate__delay-2s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/16.png"></li>
				<li class="animate__animated animate__delay-1s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/17.png"></li>
				<li class="animate__animated animate__delay" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/18.png"
					 style="width:50%;"></li>
			</ul>
			<ul>
				<li class="animate__animated animate__delay" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/19.png"></li>
				<li class="animate__animated animate__delay-1s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/txc.png"></li>
				<li class="animate__animated animate__delay-2s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/bm.png"></li>
				<li class="animate__animated animate__delay-3s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/22.png"></li>
				<li class="animate__animated animate__delay-4s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/xiandou.png"></li>
				<li class="animate__animated animate__delay-3s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/jd.png"></li>
				<li class="animate__animated animate__delay-2s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/taima.png"></li>
				<li class="animate__animated animate__delay-1s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/xiaoma.png"></li>
				<li class="animate__animated animate__delay" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/xc.png"
					 style="width:50%;"></li>
			</ul>
			<ul>
				<li class="animate__animated animate__delay" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/28.png"></li>
				<li class="animate__animated animate__delay-1s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/29.png"></li>
				<li class="animate__animated animate__delay-2s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/30.png"></li>
				<li class="animate__animated animate__delay-3s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/31.png"></li>
				<li class="animate__animated animate__delay-4s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/32.png"></li>
				<li class="animate__animated animate__delay-3s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/33.png"></li>
				<li class="animate__animated animate__delay-2s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/34.png"></li>
				<li class="animate__animated animate__delay-1s" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/35.png"></li>
				<li class="animate__animated animate__delay" :class="{'animate__bounceInLeft':classShow}"><img src="../../public/static/icon/36.png"
					 style="width:50%;"></li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				classShow: false
			}
		},
		methods: {
			handleClassShow() {
				this.classShow = true
			},
			hanleClassLeave() {
				this.classShow = false
			},
			toDasibuluo() {
				window.open('https://www.dasibuluo.com')
			}
		}
	}
</script>

<style>
	.fourth_page {
		background: url(../../public/static/indexPage/thirdPage.png);
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.partner_area {
		width: 95%;
		border-radius: 15px;
		background: #fff;
		padding: 70px 20px;
	}

	.partner_area ul li {
		list-style-type: none;
		box-sizing: border-box;
		flex: 0 0 10%;
	}

	.partner_area ul {
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
	}

	.partner_area ul li {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 110px;
		line-height: 130px;
		margin: 0 auto;
	}

	ul {
		padding-inline-start: 0px;
	}

	.partner_area ul li img {
		width: 100px;
		/*height: 70px;*/
	}

	/* animate动画 */
	:root {
		--animate-delay: 0.1s;
	}
</style>
