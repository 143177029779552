<template>
  <div class="rr" style="position: relative">
    <div class="banner">
      <div id="nav">
        <img src="../../public/static/indexPage/logo.png" alt="" class="logo" />
        <ul>
          <li><router-link to="/">首页</router-link></li>
          <span>/</span>
          <li><router-link to="/about">关于驾御</router-link></li>
          <span>/</span>
          <li><router-link to="/product">产品</router-link></li>
          <span>/</span>
          <li><router-link to="/news">新闻动态</router-link></li>
          <span>/</span>
          <!--                    <li><router-link to="/time">时光溯影</router-link></li>-->
          <!--                    <span>/</span>-->
          <li><router-link to="/contact">联系我们</router-link></li>
        </ul>
      </div>
    </div>
    <div
      v-bind:class="{ dis: !dis, 'div-css': dis }"
      @click="onDisplay"
      style="text-align: center;"
    >
      <div
        style="margin:300px auto;overflow:hidden; width:600px; height:230px;background-color: white;border-radius: 10px;"
      >
        <div style="overflow-x: auto;white-space: nowrap;">
          <div
            v-for="(value, index) in imgItems"
            :key="index"
            style="display: inline;position: relative;"
          >
            <img :src="value.src" class="img" style="border-radius: 10px;" />
            <div class="title-tcs">
              {{ value.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="create">
        <!--                <div class="waterfall-div">-->
        <!--                    <div class="t-cs" @click="clickThanksgiving">-->
        <!--                        <img src="../../public/static/time/hz.jpg" class="img">-->
        <!--                        <div class="title">-->
        <!--                            2018.11感恩节-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="t-cs" @click="onBirthdayParty">-->
        <!--                        <img src="../../public/static/time/qdg.jpg" class="img">-->
        <!--                        <div class="title">-->
        <!--                            2018.12生日会-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="t-cs" @click="onSdayParty">-->
        <!--                        <img src="../../public/static/time/srg.jpg" class="img">-->
        <!--                        <div class="title">-->
        <!--                            2019.4生日会-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="waterfall-div"  @click="onWork">-->
        <!--                    <div class="t-cs">-->
        <!--                        <img src="../../public/static/time/rztbmhb.jpg" class="img">-->
        <!--                        <div class="title">-->
        <!--                            2019工作大会-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="t-cs" @click="onAnnualMeeting">-->
        <!--                        <img src="../../public/static/time/nh_2.jpg" class="img">-->
        <!--                        <div class="title">-->
        <!--                            2019年会-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="t-cs">-->
        <!--                        <img src="../../public/static/time/nhzyx.jpg" class="img">-->
        <!--                        <div class="title">-->
        <!--                            2019元宵节-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="waterfall">-->
        <!--                    <div class="t-cs">-->
        <!--                        <img src="../../public/static/time/zjpz.jpg" class="img-cs">-->
        <!--                        <div class="title">-->
        <!--                            公司日常之我们在正经地拍证件照-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="t-cs">-->
        <!--                        <img src="../../public/static/time/nh_1.jpg" class="img-cs">-->
        <!--                        <div class="title">-->
        <!--                            看到了吗，这是我用零食为你打下的江山-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <div class="waterfall-div">
          <div class="t-cs">
            <img src="../../public/static/time/hz.jpg" class="img" />
            <div class="title">
              2018.11感恩节
            </div>
          </div>
          <div class="t-cs">
            <img src="../../public/static/time/qdg.jpg" class="img" />
            <div class="title">
              2018.12生日会
            </div>
          </div>
          <div class="t-cs">
            <img src="../../public/static/time/srg.jpg" class="img" />
            <div class="title">
              2019.4生日会
            </div>
          </div>
        </div>
        <div class="waterfall-div">
          <div class="t-cs">
            <img src="../../public/static/time/rztbmhb.jpg" class="img" />
            <div class="title">
              2019工作大会
            </div>
          </div>
          <div class="t-cs">
            <img src="../../public/static/time/nh_2.jpg" class="img" />
            <div class="title">
              2019年会
            </div>
          </div>
          <div class="t-cs">
            <img src="../../public/static/time/nhzyx.jpg" class="img" />
            <div class="title">
              2019元宵节
            </div>
          </div>
        </div>
        <div class="waterfall">
          <div class="t-cs">
            <img src="../../public/static/time/zjpz.jpg" class="img-cs" />
            <div class="title">
              公司日常之我们在正经地拍证件照
            </div>
          </div>
          <div class="t-cs">
            <img src="../../public/static/time/nh_1.jpg" class="img-cs" />
            <div class="title">
              看到了吗，这是我用零食为你打下的江山
            </div>
          </div>
        </div>
      </div>
    </div>
    <BackToTop></BackToTop>
    <bottomNav></bottomNav>
  </div>
</template>

<script>
// import headNav from "@/components/headNav";
import bottomNav from "@/components/bottomNav";
import BackToTop from "@/components/BackToTop";
export default {
  name: "retrospecttime",
  components: {
    bottomNav,
    BackToTop
  },
  data: function() {
    return {
      dialog: false,
      imgItems: [],
      dis: false,
      gej: [
        {
          src: require("../../public/static/time/ms.jpg"),
          title: "感恩节之美食集合"
        },
        {
          src: require("../../public/static/time/pzwb.jpg"),
          title: "拍照完毕，来享受美食咯"
        },
        {
          src: require("../../public/static/time/xjj.jpg"),
          title: "小姐姐似乎看到了什么有趣的画面……"
        },
        {
          src: require("../../public/static/time/xx.jpg"),
          title: "休息，休息一下~"
        }
      ], // 感恩节
      onebotdy: [
        {
          src: require("../../public/static/time/sr_1.jpg"),
          title: "HR组织做一个性格小测验"
        },
        {
          src: require("../../public/static/time/sr_2.jpg"),
          title: "大家在认真做题"
        },
        {
          src: require("../../public/static/time/sr_3.jpg"),
          title: "蛋糕已装盘，分蛋糕啦"
        },
        {
          src: require("../../public/static/time/sr_4.jpg"),
          title: "美丽的小姐姐们"
        },
        {
          src: require("../../public/static/time/sr_5.jpg"),
          title: "认真地填卷子"
        }
      ], // 12生日会
      sbotdy: [
        {
          src: require("../../public/static/time/sr_1.jpg"),
          title: "给4月份过生日的同事庆生"
        },
        {
          src: require("../../public/static/time/sr_2.jpg"),
          title: "两位白羊座寿星~"
        }
      ], // 4生日会
      work: [
        {
          src: require("../../public/static/time/gzdhtl.jpg"),
          title: "2019工作大会上大家在讨论交流"
        }
      ],
      meeting: [
        {
          src: require("../../public/static/time/nhcq.jpg"),
          title: "19年年会"
        },
        {
          src: require("../../public/static/time/nhzyx.jpg"),
          title: "19年年会大家在做游戏"
        }
      ]
    };
  },
  created() {},
  methods: {
    clickThanksgiving() {
      this.dis = true;
      this.imgItems = this.gej;
    },
    onBirthdayParty() {
      this.dis = true;
      this.imgItems = this.onebotdy;
    },
    onSdayParty() {
      this.dis = true;
      this.imgItems = this.sbotdy;
    },
    onWork() {
      this.dis = true;
      this.imgItems = this.work;
    },
    onAnnualMeeting() {
      this.dis = true;
      this.imgItems = this.meeting;
    },
    onDisplay() {
      this.dis = false;
    }
  }
};
</script>

<style scoped>
#nav {
  float: right;
  position: fixed;
  width: 100%;
  min-width: 1200px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background: #ad1e23;
  transition: all 0.2s ease-in-out;
}
ul {
  float: right;
  display: flex;
  list-style: none;
  color: #fff;
}
ul li {
  font-size: 16px;
  margin: 0 30px;
}
#nav ul li a {
  color: #fff;
  text-decoration: none;
}
.logo {
  width: 150px;
}
@media screen and (min-width: 1200px) {
  .logo {
    width: 150px;
  }
  #nav {
    /* padding: 30px 0; */
  }
  #nav img {
    margin-left: 50px;
  }
}
@media screen and (min-width: 1920px) {
  ul li {
    font-size: 16px;
    margin: 0 18px;
  }
  .logo {
    width: 130px;
  }
  #nav {
    /* padding: 30px 0; */
  }
  ul {
    margin-right: 50px;
  }
  #nav img {
    margin-left: 50px;
  }
}
.waterfall {
  -moz-column-count: 2; /* Firefox */
  -webkit-column-count: 2; /* Safari 和 Chrome */
  column-count: 2;
  -moz-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  display: inline-block;
  justify-content: center;
}
.waterfall-div {
  -moz-column-count: 3; /* Firefox */
  -webkit-column-count: 3; /* Safari 和 Chrome */
  column-count: 3;
  -moz-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  display: inline-block;
}
.waterfall-div-div {
  -moz-column-count: 4; /* Firefox */
  -webkit-column-count: 4; /* Safari 和 Chrome */
  column-count: 4;
  -moz-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  display: inline-block;
}
.banner {
  /*height: 450px;*/
  background: #ad1e23;
}
.rr {
  min-width: 1024px;
  height: 100%;
}
.img-cs {
  width: 600px;
  height: 350px;
  margin: 3px 4px;
}
.img {
  width: 400px;
  height: 200px;
  margin: 3px 4px;
}
.create {
  max-width: 1267px;
  padding: 120px 220px;
}
.t-cs {
  position: relative;
}
.title {
  position: absolute;
  top: 100px;
  left: 150px;
  color: #000000;
  filter: alpha(opacity=90);
  background-color: gainsboro;
  opacity: 0.7;
  padding: 5px;
  border-radius: 5px;
}
.img-title {
  position: absolute;
  top: 10%;
  left: 15%;
  color: #000000;
  padding: 5px;
  background-color: gainsboro;
  border-radius: 5px;
  z-index: 1;
  box-shadow: 5px 2px 6px #000;
}
.dis {
  display: none;
}
.pop {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  background-color: gainsboro;
  opacity: 0.7;
  position: relative;
}
.div-css {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow-y: auto;
  background-color: white;
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* IE6和部分IE7内核的浏览器(如QQ浏览器)会读懂，但解析为透明 */
}
.div-css div img {
  position: relative; /* 设置子元素为相对定位，可让子元素不继承Alpha值 */
}
.title-tcs {
  position: absolute;
  top: -20px;
  left: 120px;
  color: #000000;
  filter: alpha(opacity=90);
  background-color: gainsboro;
  opacity: 0.7;
  padding: 5px;
  border-radius: 5px;
}
</style>