import Vue from 'vue'
import Router from 'vue-router'
import Index from './views/Index.vue'
import About from './views/About.vue'
import Contact from './views/Contact.vue'
import Demo from './views/Demo.vue'
import News from './views/News.vue'
import Yu from './views/Yu.vue'
import ttt from './views/ttt.vue'
import Time from './views/retrospecttime'
import xcsj from './views/xcsj'
import paotuan from './views/paotuan'

Vue.use(Router)

export default new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/product',
      name: 'product',
      component: Demo,
    },
    {
      path: '/news',
      name: 'news',
      component: News
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/yu',
      component: Yu
    },
    {
      path: '/ttt',
      component: ttt
    },
    {
      path: '/time',
      component: Time
    },
    {
      path: '/xcsj',
      component: xcsj
    },
	{
      path: '/paotuan',
      component: paotuan
    }
  ],
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 };
  // }
})
