<template>
  <div>
    <div class="banner">
      <headNav></headNav>
    </div>
    <div class="card">
      <router-link to="/ttt">
        <div>
          <img src="../../public/static/demo/ttt.png" alt="" class="img">
          <h4>智慧出行APP</h4>
          <p>淘途途</p>
        </div>
      </router-link>
      <router-link to="/yu">
      <div class="card_center">
        <img src="../../public/static/demo/car.png" alt="" class="img">
        <h4>智能车机</h4>
        <p>驾御·小墨鱼</p>
      </div>
      </router-link>
      <router-link to="/xcsj">
        <div class="card_center">
          <img src="../../public/static/demo/xcsj.png" alt="" class="img">
          <h4>AI智能摄像系统</h4>
          <p>炫彩瞬间</p>
        </div>
      </router-link>
      <a href="http://jiayuonline.com:8080">
        <div>
          <img src="../../public/static/demo/road.png" alt="" class="img">
          <h4>路书采集</h4>
          <p>路书采集平台</p>
        </div>
      </a>
	  <router-link to="/paotuan">
	    <div>
	      <img src="../../public/static/demo/asiku.png" alt="" class="img">
	      <h4>阿思酷</h4>
	      <p>校团外卖APP</p>
	    </div>
	  </router-link>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>


<script>
import headNav from '@/components/headNav'
import bottomNav from '@/components/bottomNav'
export default {
  components: {
    headNav,
    bottomNav
  }
}
</script>

<style scoped>
.banner {
  background: url(../../public/static/demo/banner_666.png);
  background-size: 100% 100%;
  height: 450px;
}
.img{
  width: 90%;
}
.card {
  display: flex;
  justify-content: center;
  margin: 120px 80px;
}
.card div {
  text-align: center;
  transition: all 0.1s ease-in-out;
}
.card div:hover {
  color:burlywood;
  transition: all 0.1s ease-in-out;
}
.card a {
  text-decoration: none;
  color: rgba(0,0,10,.8);
}
.card div img {
  position: relative;
  /* transition: 1s ease-in-out; */
}
.card div img:hover {
  box-shadow: 5px 5px 2px grey;
  transition: all 0.1s ease-in-out;
}
/deep/ .nav-bar{
  color:#000;
}
/deep/ #nav .nav-bar li a{
  color:#000;
}
/*.card_center {*/
/*  margin: 0px 60px;*/
/*}*/
@media screen and (min-width: 1440px) {
  .banner {
    height: 450px;
  }
  /deep/.nav-bar{
    color:#000;
  }
  /deep/ #nav .nav-bar li a{
    color:#000;
  }
}
@media screen and (min-width: 1920px) {
  .banner {
    height: 580px;
  }
  .nav-bar{
    color:#000;
  }
  /deep/ #nav .nav-bar li a{
    color:#000;
  }
}
</style>
