<template>
  <div>
    <p class="concept_title">核心团队</p>
    <div class="border">
      <div class="part">
        <img src="../../public/static/aboutUs/peter.png" alt="">
        <p class="name">CEO 夏建华</p>
        <p class="intro">驾御科技创始人。资深IT人士，原康柏维修中心华东华南负责人。蓝冠科技创始人，20年汽车行业及奢侈品零售业，NIKE大中华区信息产品服务，开发经验。深度越野爱好者。</p>
      </div>
      <div class="part">
        <img src="../../public/static/aboutUs/zhu.png" alt="">
        <p class="name">CSO 诸刚强</p>
        <p class="intro">驾御科技创始人，曾任职中国顶级品牌赫基国际总裁办；Lily商务时装品牌战略运营与首席信息官；NIKE大中华区首席信息官。曾服务于服务全球IT公司Microsoft，全球医药Top10品牌ElliLilly，全球消费品及医药公司Johnson&Johnson。</p>
      </div>
      <!-- <div class="part">
        <img src="../../public/static/aboutUs/eric.png" alt="">
        <p class="name">MANAGER 方旭</p>
        <p class="intro">毕业于上海交通大学。曾在德勤咨询担任咨询专家和项目负责人。前SAP研究院SAAS产品技术专家，SAP G-learning联合发起人。作为第一著作人获得United States Patent “Methodology and tools for ERP development project management”。</p>
      </div> -->
      <div class="part">
        <img src="../../public/static/aboutUs/sean.png" alt="">
        <p class="name">DMD & CTO 谢绍静</p>
        <p class="intro">曾就职于CROCS中国，塞拉尼利中国，LILY，梓灵文化等跨国及合伙人企业，历任IT负责人、信息化项目负责人、副总经理等职位，设计和实施过公司数字化和互联网化转型以及全渠道战略，16年IT从业经历，熟悉前沿技术和IT赋能落地。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .concept_title {
    font-size: 36px;
    text-align: center;
    font-weight: 300;
    margin-top: 60px;
  }
  .line {
    width: 80px;
    margin: 30px auto;
  }
  .part img {
    width: 200px;
  }
  .border {
    margin: 40px auto 30px auto;
    width: 92%;
  }
  .part {
    padding: 40px;
  }
/* @media screen and (min-width: 1440px) {
  .concept_title {
    font-size: 36px;
    text-align: center;
    font-weight: 300;
    margin-top: 60px;
  }
  .line {
    width: 80px;
    margin: -30px auto;
  }
  .part img {
    width: 200px;
  }
  .border {
    margin: 40px auto 30px auto;
    width: 92%;
  }
  .part {
    padding: 40px;
  }
} */
@media screen and (min-width: 1920px) {
  .concept_title {
    font-size: 50px;
    text-align: center;
    font-weight: 300;
    margin-top: 80px;
  }
  .line {
    width: 130px;
    margin: -30px auto;
  }
  .part img {
    width: 200px;
  }
  .border {
    margin: 100px auto 0 auto;
    width: 80%;
  }
  .part {
    padding: 40px;
  }
}
.line {
  border-top: 1px solid #ad1e23;
}
.border {
  max-width: 1920px;
  display: flex;
}
.part {
  flex-grow: 1;
  text-align: center;
}
.part .name {
  text-align: center;
}
.part .intro {
  text-align: justify;
}
*p {
  margin: 0;
  padding: 0;
}
</style>
