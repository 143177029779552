<template>
  <div>
    <headNavNo></headNavNo>
    <div class="board">
      <img src="../../public/static/ttt/ttt-logo.png" alt="" id="ttt-logo">
      <p>淘途途是一款以PGC&UGC内容为基础的智慧旅途APP。</p>
      <p>手机端和车机端无缝连接，给您“行前、行中、行后”的一站式体验服务。</p>
      <p>淘途途聚集着大量的旅游大咖，提供海量高品质的精彩游记。行前智能规划、千人千面精品推荐和创新网联功能，为您的出行保驾护航。</p>
      <p>轻松记录精彩瞬间和行程轨迹，实时分享，快速晋级旅游大咖。</p>
      <img src="../../public/static/ttt/ttt.png" alt="" id="ttt">
      <div class="feature s1">
        <h1>场景识别</h1>
        <div class="border_bottom"></div>
        <p>推荐服务与场景自动匹配，具备更佳的实时性，用户体验更加优化</p>
        <img src="../../public/static/ttt/s1.png" alt="" id="s1">
      </div>
      <div class="box">
        <div class="feature">
        <img src="../../public/static/ttt/s2.png" alt="" class="s2">
        <p>当用户车速低于40M/S时，淘途途会根据实时路况信息与用户喜好，推荐舒缓或快节奏的音乐</p>
      </div>
      <div class="feature">
        <img src="../../public/static/ttt/s3.png" alt="" class="s2">
        <p>在用户前往电影院的途中，淘途途会自动提示电影开场时间为用户提供电影相关音乐、故事背景、演员简介等信息</p>
      </div>
      <div class="feature">
        <img src="../../public/static/ttt/s4.png" alt="" class="s2">
        <p>在用户出游途中，淘途途会根据时间自动为您推荐时间段最佳出游事宜，并提供到各周边场景的实时距离供用户选择</p>
      </div>
      </div>
      <h3>还有更多场景……</h3>
      <div class="feature">
        <h1>千人千面</h1>
        <div class="border_bottom"></div>
        <p>通过海量数据实现精准用户定位，提供个性化服务</p>
        <img src="../../public/static/ttt/face.png" alt="" class="s3">
      </div>
      <div class="feature">
        <h1>AI导游播报</h1>
        <div class="border_bottom"></div>
        <p>在自驾沿途过程中，AI自动播报沿途景点，让自驾更有趣</p>
        <img src="../../public/static/ttt/ai-report.png" alt="" class="s3">
      </div>
      <div class="feature">
        <h1>电子路书</h1>
        <div class="border_bottom"></div>
        <p>提炼线路玩法，安心畅玩，自驾无忧</p>
        <img src="../../public/static/ttt/roadbook.png" alt="" class="s3">
      </div>
    </div>
    <router-link to="/product">
      <div class="back">
        <img src="../../public/static/back/back.png" alt="">
      </div>
    </router-link>
    <bottomNav></bottomNav>
  </div>
</template>

<script>
import headNavNo from '@/components/headNavNo'
import bottomNav from '@/components/bottomNav'
export default {
  components: {
    headNavNo,
    bottomNav
  }
}
</script>

<style scoped>
.board {
  text-align: center;
  padding-top: 200px;
}
#ttt {
  width: 60%;
  margin-top: 50px;
}
#ttt-logo {
  width: 300px;
  margin-bottom: 50px;
}
#s1 {
  width: 40%;
}
h1 {
  position: relative;
}
.box {
  display: flex;
}
.border_bottom {
  width: 100px;
  border-top: 1px solid red;
  margin: 0px auto 30px auto;
}
/* h2::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  content: '';    
  width: 0;    
  border-top: solid  #5dd7bf 1px;
} */
.s1  {
  margin-top: 50px;
}
.s2 {
  width: 80%;
}
.feature {
  margin-top: 80px;
}
.feature p {
  padding: 0 50px;
}
.s3 {
  width: 40%;
}
.back img{
  position: fixed;
  left: 4%;
  bottom: 20%;
  width: 50px;
  height: 50px;
}
</style>
