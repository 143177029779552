<template>
	<div>
		<div class="banner">
			<headNav></headNav>
		</div>
		<div class="wrapper">
			<ul class="time-horizontal">
				<li @click="scroll(13)"><b></b><span>2021年02月</span></li>
				<li @click="scroll(13)"><b></b><span>2021年01月</span></li>
				<li @click="scroll(13)"><b></b><span>2020年12月</span></li>
				<li @click="scroll(19)"><b></b><span>2020年11月</span></li>
				<li @click="scroll(18)"><b></b><span>2020年8-10月</span></li>
				<li @click="scroll(17)"><b></b><span>2020年07月</span></li>
				<li @click="scroll(16)"><b></b><span>2020年05月</span></li>
				<li @click="scroll(15)"><b></b><span>2020年04月</span></li>
				<li @click="scroll(14)"><b></b><span>2020年02月</span></li>
				<li @click="scroll(13)"><b></b><span>2019年12月</span></li>
				<!-- <li @click="scroll(12)"><b></b><span>2019年11月</span></li>
        <li @click="scroll(11)"><b></b><span>2019年9月</span></li>
        <li @click="scroll(10)"><b></b><span>2019年8月</span></li> -->
				<!-- <li @click="scroll(10)"><b></b><span>2019年8月22日</span></li>
        <li @click="scroll(9)"><b></b><span>2019年8月21日</span></li>
        <li @click="scroll(8)"><b></b><span>2019年8月</span></li>
        <li @click="scroll(7)"><b></b><span>2019年7月25日</span></li>
        <li @click="scroll(6)"><b></b><span>2019年7月20日</span></li>
        <li @click="scroll(1)"><b></b><span>2019年7月</span></li>
        <li @click="scroll(0)"><b></b><span>2019年6月25日</span></li> -->
				<!-- <li @click="scroll(1)"><b></b><span>2019年6月8日</span></li> -->
				<!-- <li @click="scroll(2)"><b></b><span>2019年1月12日</span></li>
        <li @click="scroll(3)"><b></b><span>2019年1月11日</span></li>
        <li @click="scroll(4)"><b></b><span>2019年1月1日</span></li>
        <li @click="scroll(5)"><b></b><span>2018年</span></li> -->
				<!--        <li @click="scroll(6)"><b></b><span>2017年</span></li>-->
			</ul>
			<div class="box">
				<div class="inside_box" ref="inside_box19">
					<p class="date date_first" id="qqq">2021年02月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技携手极豆科技共同研发的自驾游解决方案成功入围日产汽车。
						</p>
						<br />
					</div>
				</div>
				<div class="inside_box" ref="inside_box19">
					<p class="date date_first" id="qqq">2021年01月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技自驾游解决方案《淘途途》在腾讯随行TAI平台成功发布。
						</p>
						<br />
					</div>
				</div>
				<div class="inside_box" ref="inside_box19">
					<p class="date date_first" id="qqq">2020年12月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技成功通过上海市科委评审，获得“高新技术企业”认证。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box19">
					<p class="date date_first" id="qqq">2020年11月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技自驾游出行服务在长城汽车WEY品牌APP端正式上线。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box19">
					<p class="date date_first" id="qqq">2020年11月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技与北京百度网讯科技有限公司签署战略合作协议，加入百度车联网出行服务生态圈，开发自驾游出行服务车机版小程序。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box19">
					<p class="date date_first" id="qqq">2020年11月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技携手小马立行科技有限公司共同研发的自驾游出行服务应用在斑马车机端正式上线。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box19">
					<p class="date date_first" id="qqq">2020年11月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技与北京首汽智行科技有限公司签署自驾游出行服务项目合作协议。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box19">
					<p class="date date_first" id="qqq">2020年11月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技与阿利昂斯汽车研发（上海）有限公司签署未来5年深度合作计划。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box18">
					<p class="date date_first" id="qqq">2020年8-10月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技成功与美团、华住、亚朵、汇智签署合作协议，创建自驾游出行服务酒店生态圈，并已完成API对接，实现用户运营和业务闭环。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box17">
					<p class="date date_first" id="qqq">2020年7月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技与腾讯车联达成合作协议，加入腾讯随行TAI出行服务生态圈，开发自驾游出行服务车机版小程序。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box16">
					<p class="date date_first" id="qqq">2020年5月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技与仙豆科技签署在长城汽车前装车机平台和手机平台上提供出行服务应用开发与运营服务的合作协议，并同月开始自驾游路书内容和TTS播报内容的系统对接。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box">
					<p class="date date_first" id="qqq">2020年5月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技与吉利汽车在宁波吉利汽车研究院深度探讨未来在吉利全系汽车上的智慧出行应用场景与运营服务体系。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box15">
					<p class="date date_first" id="qqq">2020年4月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技与小马立行科技签署在奥迪Asterix 平台上为奥迪汽车和车主提供出行服务的应用开发与运营服务协议。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box">
					<p class="date date_first" id="qqq">2020年4月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技与极豆科技签署在宝马汽车前装车机平台项目提供出行服务应用开发与运营服务的合作协议，并完成技术和内容开发，开始系统对接工作。
						</p>
						<br />
					</div>
				</div>
				<div class="inside_box" ref="inside_box14">
					<p class="date date_first" id="qqq">2020年2月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							2月16日 驾御科技在疫情期间开发出公益性质的《疫可查》小程序和H5
							，该小程序结合大数据能迅速为使用者提供所在周边最详尽的疫情状况，同时可以为企事业单位的开工复产时对员工的健康检测助力。“小心意、大力量”，希望能在特殊时期献一份微薄之力，尽一份公民义务。
						</p>
						<br />
					</div>
				</div>
				<div class="inside_box" ref="inside_box13">
					<p class="date date_first" id="qqq">2019年12月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技CEO夏建华与深圳小马立行科技有限公司高层在北京达成共同开拓车载智慧自驾生态建设的共识，双方并完成签署了深度战略合作协议。
						</p>
						<br />
					</div>
				</div>
				<div class="inside_box" ref="inside_box12">
					<p class="date date_first" id="qqq">2019年11月</p>
					<div class="entire entire_first">
						<p class="title">
							驾御科技应邀参观保定长城汽车总部，并签署了一级供应商资质
						</p>
						<p class="content">
						</p>
						<br />
					</div>
				</div>
				<div class="inside_box" ref="inside_box11">
					<p class="date date_first" id="qqq">2019年9月</p>
					<div class="entire entire_first">
						<p class="title">
						</p>
						<p class="content">
							驾御科技与极豆科技签署为极豆科技服务的主机厂品牌及车联网用户提供出行服务应用开发与运营服务的商务协议。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box10">
					<p class="date date_first" id="qqq">2019年8月</p>
					<div class="entire entire_first">
						<p class="title">
							“未来汽车产业联盟，打造全新出行方式”研讨会成功召开
						</p>
						<p class="content">
							2019年8月22日，由奉贤区经济委员会、奉贤区科学技术委员会、奉贤区人力资源和社会保障局指导，上海市工业综合开发区、未来汽车产业联盟主办，上海市工业综合开发区商会、凤创谷科创基地、杨妈妈创新创业服务中心、驾御科技联合发起的“未来汽车产业联盟，打造全新出行方式”研讨会圆满结束。
						</p>
						<br />
					</div>
				</div>
				<div class="inside_box" ref="inside_box9">
					<p class="date date_first" id="qqq">2019年8月</p>
					<div class="entire entire_first">
						<p class="title">2019年8月21世纪经济报道</p>
						<p class="content">
							德勤中国、赛迪顾问等媒体对驾御科技进行了专题采访
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box8">
					<p class="date date_first" id="qqq">2019年8月</p>
					<div class="entire entire_first">
						<p class="title">
							驾御科技与北京梧桐车联科技有限责任公司就智慧出行业务达成战略合作协议。
						</p>
						<p class="content"></p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box7">
					<p class="date date_first" id="qqq">2019年7月</p>
					<div class="entire entire_first">
						<p class="title">“汽车新零售沙龙”成功举办</p>
						<p class="content">
							2019年7月25日下午，由驾御科技、雅各布专栏联合主办“汽车新零售沙龙”在越秀大厦茶识地举办。多位车联网从业人士、业内资深专家出席了本次活动。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box6">
					<p class="date date_first" id="qqq">2019年7月</p>
					<div class="entire entire_first">
						<p class="title">
							驾御科技与芜湖雄狮汽车科技有限公司成功签署战略合作协议。
						</p>
						<p class="content">
							2019年7月20日驾御科技与芜湖雄狮汽车科技有限公司成功签署战略合作协议。
						</p>
						<br />
					</div>
				</div>

				<div class="inside_box" ref="inside_box0">
					<p class="date date_first" id="qqq">2019年7月</p>
					<div class="entire entire_first">
						<p class="title">
							驾御科技荣获2019物联中国最具投资价值物联网项目十强！
						</p>
						<p class="content">
							2019年7月11日，在厦门举行的2019物联中国项目路演大赛全国总决赛中，驾御科技的项目《炫彩瞬间——AI智能摄像系统》荣获2019物联中国最具投资价值物联网项目全国十强！
						</p>
					</div>
				</div>
				<div class="inside_box" ref="inside_box0">
					<p class="date date_first" id="qqq">2019年6月</p>
					<div class="entire entire_first">
						<p class="title">
							"物联中国——寻找最具影响力、最具投资价值物联网项目路演”喜获二等奖
						</p>
						<p class="content">
							“物联中国—寻找最具影响力、最具投资价值物联网项目”年度盛典，为全球先进的物联网技术、资金、人才搭建一个交流平台，推进资本与项目对接、资本与人才对接，引发“知本”与资本的碰撞，希望借此推动政府部门重视并借力物联网的发展加快转变经济发展方式，推进智能制造等产业结构战略性调整。
						</p>
					</div>
				</div>
				<!-- 19.6 -->
				<div class="inside_box" ref="inside_box0">
					<p class="date date_first" id="qqq">2019年6月</p>
					<div class="entire entire_first">
						<p class="title">2019.6.8淘途途APP正式上线</p>
						<p class="content">「乐在淘中，淘途途」</p>
					</div>
				</div>
				<!-- 19.1 -->
				<div class="inside_box" ref="inside_box1">
					<p class="date date_first" id="qqq">2019年1月</p>
					<div class="entire entire_first">
						<p class="title">群英汇聚 智慧出行高端沙龙</p>
						<p class="content">
							2019年1月12日下午，驾御在杭州市千岛湖随园别墅举行了以“新造车新能源新科技
							智慧出行高端沙龙”为主题的圆桌会议。围绕MaaS重构智慧出行新生态，重新定义旅游，再造一个移动零售空间议题展开讨论。
						</p>
					</div>
				</div>
				<!-- 19.1 -->
				<div class="inside_box" ref="inside_box2">
					<p class="date date_first" id="qqq">2019年1月</p>
					<div class="entire entire_first">
						<p class="title">
							受邀出席2019新工业产业生态创新峰会暨工业4.0高峰论坛
						</p>
						<p class="content">
							2019年1月11日，“2019新工业产业生态创新峰会暨工业4.0高峰论坛”在上海第二工业大学学术交流中心举行。驾御科技受邀参加了此次高峰论坛。
						</p>
					</div>
				</div>
				<!-- 18.11 -->
				<div class="inside_box" ref="inside_box3">
					<p class="date date_first" id="qqq">2018年11月</p>
					<div class="entire entire_first">
						<p class="title">云南新品发布会</p>
						<p class="content">
							2018年11月18日上午，来自驾御科技高层管理人员、金融系统主管单位、云南省物流行业协会、汽配行业协会、车辆运营安全单位、连锁4s店集团、新闻媒体等近百位嘉宾，在云南省昆明市世纪金源大饭店出席活动，并为全域旅游数字新出行启动仪式揭幕。
						</p>
					</div>
				</div>
				<!-- 18.8 -->
				<div class="inside_box">
					<p class="date">2018年8月</p>
					<div class="entire">
						<p class="title">与宁夏丝路风情旅游签订战略合作协议</p>
						<p class="content">
							携手共建以宁夏回族自治区为中心向西北区域辐射的智慧出行生态圈，致力于共同推进（宁夏）全域智慧旅游及出行产业的业务。
						</p>
					</div>
				</div>
				<!-- 18.5 -->
				<div class="inside_box">
					<p class="date">2018年5月</p>
					<div class="entire">
						<p class="title">与景洪州政府签订战略协议</p>
						<p class="content">
							通过数字自驾游的方式，以一“机”畅游整个云南，展开智慧出行，智慧旅游建设等合作。
						</p>
					</div>
				</div>
				<!-- 18.3 -->
				<div class="inside_box">
					<p class="date">2018年3月</p>
					<div class="entire">
						<p class="title">“马龙论剑”</p>
						<p class="content">
							3月18日，第3届云旅四季论坛全域旅游推介活动在曲靖市马龙县拉开帷幕。在本届论坛上，马龙县人民政府与驾御科技签订了战略合作协议，将在旅游新产品新业态开发、智慧旅游建设、旅游研究等方面开展合作。
						</p>
					</div>
				</div>
				<!-- 18.3 -->
				<div class="inside_box">
					<p class="date">2018年3月</p>
					<div class="entire">
						<p class="title">自驾游大会</p>
						<p class="content">
							3月6日，2018年第5届自驾游大会在上海市举办，在驾御分会场上，驾御CSO诸刚强先生主持了“数字科技重构智驾生态”和“2018数字旅游新物种——智驾游新IP时代”两场圆桌会议。
						</p>
					</div>
				</div>

				<!-- 18.2 -->
				<div class="inside_box">
					<p class="date">2018年2月</p>
					<div class="entire">
						<p class="title">首批国外战略合作伙伴签署</p>
						<p class="content">
							2018年，驾御科技陆续与CONEVRSYS、DENTSU、KPMG、TECH
							MAHINDRA等多家企业签署战略合作协议，成为合作伙伴。
						</p>
					</div>
				</div>
				<!-- 17.12 -->
				<div class="inside_box" ref="inside_box4">
					<p class="date">2017年12月</p>
					<div class="entire">
						<p class="title">首批线下战略合作伙伴签署</p>
						<p class="content">
							2017年，驾御科技陆续与途牛网、小蚁、图形旅游、意时网、爱驾网、风线骑行、穿越汇等多家企业签署战略合作协议，成为合作伙伴。
						</p>
					</div>
				</div>
				<!-- 17.12 -->
				<div class="inside_box">
					<p class="date">2017年12月</p>
					<div class="entire">
						<p class="title">首批数字线路上线</p>
						<p class="content">
							驾御首批50条数字线路正式上线斑马车机和飞猪平台。包括华东、华南地区、川藏线G318数字线路及其它热门旅游线路。
						</p>
					</div>
				</div>
				<!-- 17.10 -->
				<div class="inside_box">
					<p class="date">2017年10月</p>
					<div class="entire">
						<p class="title">驾御智行平台项目启动</p>
						<p class="content">
							与众多区域旅游管理部门、旅游业实体及旅行传媒合作构建全数字化旅行服务资源库和相关数字版权保护。
						</p>
					</div>
				</div>
				<!-- 17.5 -->
				<div class="inside_box">
					<p class="date">2017年5月</p>
					<div class="entire">
						<p class="title">互联网车机首次内测</p>
						<p class="content">
							5月20日，驾御联合斑马及特邀消费者在常熟举办了两天一夜的自驾亲子游活动，同时也是对我们Smart
							line的首次内测。
						</p>
					</div>
				</div>
				<!-- 16.10 -->
				<div class="inside_box" ref="inside_box5">
					<p class="date">2016年10月</p>
					<div class="entire">
						<p class="title">汽摩俱乐部活动</p>
						<p class="content">公司组织汽摩俱乐部会员，参加阿拉善英雄大会。</p>
					</div>
				</div>
				<!-- 16.8 -->
				<div class="inside_box">
					<p class="date">2016年08月</p>
					<div class="entire">
						<p class="title">首位战略合作伙伴签署</p>
						<p class="content">
							2016年8月，驾御与斑马签署战略合作协议，由驾御科技负责互联网车机旅途平台设计。
						</p>
					</div>
				</div>
				<!-- 16.7 -->
				<div class="inside_box">
					<p class="date">2016年07月</p>
					<div class="entire">
						<p class="title">公司成立</p>
						<p class="content">2016年7月4日，驾御科技正式成立！</p>
					</div>
				</div>
			</div>
		</div>
		<BackToTop></BackToTop>
		<bottomNav></bottomNav>
	</div>
</template>

<script>
	import headNav from "@/components/headNav";
	import bottomNav from "@/components/bottomNav";
	import BackToTop from "@/components/BackToTop";
	export default {
		data() {
			return {
				newsArr: [{
						time: '2020年5月',
						title: '驾御科技与仙豆科技签署在长城汽车前装车机平台和手机平台上提供出行服务应用开发与运营服务的合作协议，并同月开始自驾游路书内容和TTS播报内容的系统对接。'
					},
					{
						time: '2020年5月',
						title: '驾御科技与吉利汽车在宁波吉利汽车研究院深度探讨未来在吉利全系汽车上的智慧出行应用场景与运营服务体系。'
					},
					{
						time: '2020年4月',
						title: '驾御科技与小马立行科技签署在奥迪Asterix 平台上为奥迪汽车和车主提供出行服务的应用开发与运营服务协议。'
					},
					{
						time: '2020年4月',
						title: '驾御科技与极豆科技签署在宝马汽车前装车机平台项目提供出行服务应用开发与运营服务的合作协议，并完成技术和内容开发，开始系统对接工作。'
					},
					{
						time: '2020年2月',
						title: '2月16日 驾御科技在疫情期间开发出公益性质的《疫可查》小程序和H5 ，该小程序结合大数据能迅速为使用者提供所在周边最详尽的疫情状况，同时可以为企事业单位的开工复产时对员工的健康检测助力。“小心意、大力量”，希望能在特殊时期献一份微薄之力，尽一份公民义务。'
					},
					{
						time: '2019年12月',
						title: '驾御科技CEO夏建华与深圳小马立行科技有限公司高层在北京达成共同开拓车载智慧自驾生态建设的共识，双方并完成签署了深度战略合作协议。'
					},
					{
						time: '2019年11月',
						title: '驾御科技应邀参观保定长城汽车总部，并签署了一级供应商资质'
					},
					{
						time: '2019年9月',
						title: '驾御科技与极豆科技签署为极豆科技服务的主机厂品牌及车联网用户提供出行服务应用开发与运营服务的商务协议。'
					},
				]
			}
		},
		components: {
			headNav,
			bottomNav,
			BackToTop
		},
		methods: {
			scroll(index) {
				// console.log(this.$refs.inside_box)
				window.scrollTo({
					behavior: "smooth",
					top: this.$refs["inside_box" + index].offsetTop - 60
				});
			},
		}
	};
</script>

<style scoped>
	@font-face {
		font-family: YaHei Bold;
		src: url("../../public/static/font/Bold.ttf");
	}

	.time-horizontal {
		list-style-type: none;
		border-top: 2px solid #ad1e23;
		width: 100%;
		padding: 0px;
		margin: 100px auto 150px auto;
		display: block;
	}

	.time-horizontal li {
		float: left;
		position: relative;
		text-align: center;
		width: 10%;
		padding-top: 10px;
	}

	.time-horizontal li b:before {
		content: "";
		position: absolute;
		top: -7px;
		left: 47%;
		width: 12px;
		height: 12px;
		border: 2px solid #4c4c4c;
		border-radius: 8px;
		background: #4c4c4c;
		cursor: pointer;
	}

	.time-horizontal li:nth-child(2n) span {
		position: absolute;
		top: -40px;
		left: 23%;
		z-index: 1;
		width: 110px;
	}

	.time-horizontal li:nth-child(2n + 1) span {
		position: absolute;
		top: 20px;
		left: 23%;
		z-index: 1;
		width: 110px;
	}

	p {
		margin: 0;
		padding: 0;
	}

	.wrapper {
		margin: 50px auto;
		display: flex;
		justify-items: center;
		align-content: center;
		flex-direction: column;
		width: 1000px;
	}

	.entire {
		padding-left: 80px;
		letter-spacing: 1px;
		padding-top: 80px;
	}

	.title {
		font-size: 24px;
		/* font-weight: bold; */
		font-family: YaHei Bold;
		letter-spacing: 1px;
	}

	.content {
		font-size: 20px;
		padding-top: 20px;
		width: 700px;
	}

	.banner {
		background: url(../../public/static/news/banner_b.png);
		background-size: 100% 100%;
		height: 450px;
	}

	.inside_box {
		display: flex;
	}

	.date {
		border-right: 2px dashed #bbbbbb;
		color: #262626;
		font-size: 20px;
		font-family: YaHei Light;
		padding-right: 60px;
		padding-top: 80px;
		text-align: right;
		width: 200px;
	}

	.date_first {
		padding-top: 20px;
	}

	.entire_first {
		padding-top: 20px;
	}

	@media screen and (min-width: 1920px) {
		.banner {
			height: 580px;
		}

		.wrapper {
			width: 1200px;
		}
	}
</style>
