<template>
  <div>
    <div class="nav_bottom">
      <ul class="nav_bar">
        <li><router-link to="/">首页</router-link></li>
        <span>/</span>
        <li><router-link to="/about">关于驾御</router-link></li>
        <span>/</span>
        <li><router-link to="/product">产品</router-link></li>
        <span>/</span>
        <li><router-link to="/news">新闻动态</router-link></li>
        <span>/</span>
        <!--        <li><router-link to="/time">时光溯影</router-link></li>-->
        <!--        <span>/</span>-->
        <li><router-link to="/contact">联系我们</router-link></li>
      </ul>
      <div class="nav_footer">
        <div class="com_add">
          <p>驾御信息科技（上海）有限公司</p>
          <p>沪ICP备19016267号-1</p>
        </div>
        <div class="footer">
          <p class="footer-text">
            信息产业部备案管理系统网址为:
          </p>
          <a class="footer-url" href="http://beian.miit.gov.cn"
            >beian.miit.gov.cn</a
          >
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@media screen and (min-width: 1220px) {
  .nav_bottom {
    display: flex;
    justify-content: space-around;
    padding: 30px 100px 40px 100px;
  }
  .nav_bar{
    font-size: 14px;
    margin-top: 10px;
  }
  .nav_bottom ul span {
    margin: 0 20px;
  }
  .com_add > p:last-child {
    margin-left: 20px;
  }
  .footer{
    letter-spacing: 0.1em;
  }
  .footer .footer-url {
    margin-left: 16px;
  }
}
@media screen and (min-width: 1920px) {
  .com_add > p:last-child {
    margin-left: 100px;
  }

  .nav_bottom {
    display: flex;
    padding: 30px 0 40px 150px;
  }
  .nav_bottom ul span {
    margin: 0 40px;
  }
  .com_add > p:last-child {
    margin-left: 100px;
  }
}
.nav_bottom {
  color: #fff;
  background: #171717;
  padding: 30px 100px 40px 100px;
}
.nav_bottom ul span {
  margin: 0 20px;
}
.nav_bottom ul {
  display: flex;
  list-style-type: none;
  padding: 0;
}
.com_add {
  display: flex;
  letter-spacing: 0.1em;
}
.footer {
  display: flex;
}
.footer a {
  color: #fff;
}
.com_add p {
  margin: 0;
}
.nav_bottom ul li a {
  color: #fff;
  text-decoration: none;
}
ul li a:hover {
  text-decoration: underline;
}
</style>
