<template>
  <div>
    <div class="banner">
      <headNav></headNav>
    </div>
    <p class="title"><span></span>CONTACT US / <span>联系我们</span></p>
    <div class="line"></div>
    <div class="map"> </div>
    <div class="box">
        <div class="info">
          <img src="../../public/static/contact/location.png" alt="">
          <p>地址</p>
          <p>上海市闵行区吴中路1059号4F</p>
        </div>
        <div class="info">
          <img src="../../public/static/contact/phone.png" alt="">
          <p>服务热线</p>
          <p>400-0678-790</p>
          <p>021-64262842</p>
        </div>
        <div class="info">
          <img src="../../public/static/contact/mail.png" alt="">
          <p>邮箱</p>
          <p>info@jiayuonline.com</p>
        </div>
      </div>
    <bottomNav></bottomNav>
  </div>
</template>

<script>
import headNav from '@/components/headNav'
import bottomNav from '@/components/bottomNav'
export default {
  components: {
    headNav,
    bottomNav
  }
}
</script>

<style scoped>
.banner {
  background: url(../../public/static/contact/banner_bcc.png);
  background-size: 100% 100%;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  margin: 30px 0;
}
.map {
  background: url(../../public/static/contact/map.png);
  background-size: cover;
  margin-top: 50px;
}
.line {
  border-top: 1px solid #ad1e23;
  width: 130px;
  margin: 0 auto;
}
.banner {
  height: 400px;
}
.info {
  position: relative;
  background: #fff !important;
  text-align: center;
  margin: 0 auto;
  box-shadow: 1px 2px 6px grey;
}
.box {
  display: flex;
}
.info {
  top: -40px;
  padding: 20px 50px;
  width: 200px;
  font-size: 12px;
}
.info img {
  width: 60px;
  top: -40px;
  left: 75px;
  position: absolute;
}
.map {
    height: 400px;
  }
.banner {
    height: 580px;
}
@media screen and (min-width: 1440px) {
  .banner {
    height: 450px;
  }
  .title {
    font-size: 30px;
    margin: 50px 0;
  }
  .line {
    width: 130px;
    margin: 0 auto;
  }
  .map {
    height: 650px;
  }
  .info {
    top: -80px;
    padding: 20px 50px;
    width: 250px;
    font-size: 16px;
  }
  .info img {
    width: 80px;
    top: -60px;
    left: 86px;
  }
}
@media screen and (min-width: 1920px) {
  .banner {
    height: 580px;
  }
  .title {
    font-size: 30px;
    margin: 50px 0;
  }
  .line {
    width: 130px;
    margin: 0 auto;
  }
  .map {
    height: 650px;
  }
  .info {
    top: -60px;
    padding: 20px 100px;
    width: 440px;
  }
  .info img {
    width: 100px;
    top: -80px;
    left: 175px;
  }
}
</style>
